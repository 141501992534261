import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/packages/login/src/components/LanguageCombobox/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentWrapper","StyledPage"] */ "/app/onlyoffice/src/client/packages/login/src/components/Layout.styled.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/onlyoffice/src/client/packages/login/src/components/SimpleNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Scrollbar"] */ "/app/onlyoffice/src/client/packages/shared/components/scrollbar/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/flags/en-US.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/logo/favicon.ico");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.apple.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.facebook.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.google.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.linkedin.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.microsoft.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/share.zoom.react.svg");
;
import(/* webpackMode: "eager" */ "/app/onlyoffice/src/client/public/images/thirdparties/twitter.svg");
